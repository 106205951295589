import React from 'react';
import { Text,Image } from '@sitecore-jss/sitecore-jss-react';
import StarRatingV2 from '../StarRatingV2';
import { loader as gqlLoader } from 'graphql.macro';
import GraphQLData from '../../lib/GraphQLData';
import { NavLink } from 'react-router-dom';
import i18next from 'i18next';
import ProductReviews from "../../utils/ProductReviews/index.js";
import { productClickTagging } from '../Tagging';

const RelatedProductsQuery = gqlLoader('./RelatedProductsQuery.graphql');

const RelatedProducts = (props) => {
  const graphQLResult = props.relatedProductsQuery;
  const { datasource } = graphQLResult;
  let centerClass = '';

  return (
    <div className={'RelatedProducts container margin_custom' + (props.fields.designVariant && props.fields.designVariant.value)}>
      <div className="row related_product_custom">
        <div className="col-md-12 pad">
          <Text field={props.fields.RelatedProductsHeading} className="decorated-title" tag="h1" />
        </div>
        {datasource &&
          datasource.relatedProductsList.targetItems.map((productItem, index) => {   
            centerClass = datasource.relatedProductsList.targetItems.length == 2 && index == 0 ? 'col-md-offset-2' : '';
            return (
              <NavLink key={index} to={productItem.url}>   
                <div
                  onClick={(e) => productClickTagging(e, props.sitecoreContext, index + 1)}
                  data-gtm-name={productItem.title.jss.value}
                  data-gtm-ean={productItem.id}
                  data-gtm-category={productItem.productCategories?.targetItems[0].title.value}
                  data-gtm-list="best-sellers"
                  data-gtm-position={index+1}
                  className={`col-md-4 col-sm-6 product-item text-center ${centerClass} ` + productItem.productIcons.value}>
                  <div className="portfolio-link">
                    <img loading="lazy" src={productItem.image1 && productItem.image1.src} className="img-fluid" />

                    <div className="portfolio-caption min_font">
                      <Text field={productItem.title.jss} tag="h3" />
                      <div className="rating">
                        <StarRatingV2 value={productItem.rating.numberValue} />
                        <ProductReviews productId={productItem.id} />
                      </div>
                    </div>
                  </div>
                  <div className="btn_custom_oro">
                    <span>
                      {i18next.t('DiscoverButton')}
                      <i className="fas fa-long-arrow-alt-right"></i>
                    </span>
                  </div>
                </div>
              </NavLink>
            );
          })}
      </div>
    </div>
  );
};; 

export default GraphQLData(RelatedProductsQuery, { name: 'relatedProductsQuery' })(RelatedProducts);
