import React from 'react';
import {Image, Link, Text} from '@sitecore-jss/sitecore-jss-react';
import {NavLink} from "react-router-dom";
import $ from 'jquery/dist/jquery';
import './headermultilevels.css';
import { handleNavigationClick } from '../Tagging';

class HeaderMultilevel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
    };
    this.addClassMobile = this.addClassMobile.bind(this);
  }

  componentDidMount() {
    $(window).scroll(function () {
      //sticky navbar
      var e = window,
        a = 'inner';
      if (!('innerWidth' in window)) {
        a = 'client';
        e = document.documentElement || document.body;
      }
      var width = e[a + 'Width'];
      if (width > 992) {
        if ($(this).scrollTop() > 100) {
          $('.main-hd').addClass('navbar-small');
        } else {
          $('.main-hd').removeClass('navbar-small');
        }
      }
    });

    $(window).on('resize', headerResizes);

    headerResizes();

    function headerResizes() {
      if (window.innerWidth <= 900) {
        $('.main-hd').removeClass('navbar-small');
        $('.main-hd').addClass('mobile-mode');
      } else {
        $('.main-hd').removeClass('mobile-mode');
      }

      if (window.innerWidth <= 1400 && !$('.main-hd').hasClass('navbar-small')) {
        $('.navbar-brand').addClass('small-logo');
      } else {
        $('.navbar-brand').removeClass('small-logo');
      }
    }

    // GTM EVENT: MENU - L1
    // $('.menu-hd a').off('click').on("click", function(){
    //     window.dataLayer.push({
    //       event: 'uaevent',
    //       event_name: 'menu_click',
    //       eventCategory: 'main menu navigation',
    //       eventAction: 'select::header',
    //       eventLabel: $(this).text(),
    //       click_area: 'header',
    //       breadcrumb: $(this).text(),
    //     });
    // })
  }

  addClassMobile() {
    this.setState({
      menu: !this.state.menu,
    });
  }

  setSearchState(listItem, productPageLink) {
    let categoryList = [];
    let categoryNameList = [];
    categoryList.push(listItem.id.replace(/-/g, ''));
    categoryNameList.push(listItem.fields.title.value);
    sessionStorage.setItem('filterCategories', JSON.stringify(categoryList));
    sessionStorage.setItem('filterCategoriesName', JSON.stringify(categoryNameList));
    window.location.href = productPageLink && productPageLink.value.href + '?tagId=' + listItem.id.split('-').join('').toLowerCase();
  }

  render() {
    const outlineNone = {
      outline: 'none',
    };
    return (
      <div className="row_custom_small nav-hd">
        {/* Mobile Menu */}
        <div className="slicknav_menu">
          <p aria-haspopup="true" tabIndex="0" className="slicknav_btn slicknav_collapsed" style={outlineNone}>
            <span className="slicknav_menutxt">MENU</span>
            <span
              className={this.state.menu ? 'slicknav_icon collapsed changeHamburger' : 'slicknav_icon collapsed'}
              data-toggle="collapse"
              data-target="#navigation"
              onClick={this.addClassMobile}>
              <span className="slicknav_icon-bar"></span>
              <span className="slicknav_icon-bar"></span>
            </span>
          </p>
          <ul className="slicknav_nav slicknav_hidden collapse" id="navigation">
            {this.props.fields.HeaderMenu &&
              this.props.fields.HeaderMenu.map((mainMenu, mainindex) => (
                <li
                  className="producto-nav menu-item slicknav_parent slicknav_collapsed "
                  aria-haspopup="true"
                  tabIndex="0"
                  key={`first-${mainindex}`}>
                  {mainMenu.fields.Title.value != '' && mainMenu.fields.Title.value && (
                    // <Link field={mainMenu.fields.InternalLink} className="collapsed" data-toggle="collapse" data-target={`#menu${mainindex}`}/>
                    <NavLink
                      to={mainMenu.fields.InternalLink.value.href}
                      className="collapsed"
                      data-toggle="collapse"
                      data-target={`#menu${mainindex}`}>
                      {mainMenu.fields.Title.value}
                    </NavLink>
                  )}
                  {mainMenu.fields.SubMenuLinks.length > 0 && (
                    <ul className="sub-menu slicknav_hidden collapse first_level" role="menu" aria-hidden="true" id={`menu${mainindex}`}>
                      {mainMenu.fields.SubMenuLinks &&
                        mainMenu.fields.SubMenuLinks.map((submenu, subindex) => (
                          <li className=" child-nav slicknav_collapsed slicknav_parent " key={subindex} aria-haspopup="true" tabIndex="0">
                            <div role="menuitem" className="slicknav_item slicknav_row slicknav_collapsed" style={outlineNone}>
                              {submenu.fields.SubMenuLinks && submenu.fields.SubMenuLinks.length > 0 && (
                                <>
                                  {submenu.fields.Title.value != '' && (
                                    <NavLink to={submenu.fields.InternalLink.value.href} className="collapsed">
                                      {submenu.fields.Title.value}
                                    </NavLink>
                                  )}
                                  <ul className={`sub-menu slicknav_hidden`} id={`subind_${subindex}`}>
                                    {submenu.fields.SubMenuLinks &&
                                      submenu.fields.SubMenuLinks.map((sublink, sublinkindex) => (
                                        <li className="menu-item tiret__custom " key={sublinkindex}>
                                          {sublink.fields.Title.value != '' && sublink.fields.Title.value && (
                                            <NavLink
                                              onClick={() =>
                                                handleNavigationClick('burger menu', mainMenu.fields.Title.value + '::' + sublink.fields.Title.value)
                                              }
                                              to={sublink.fields.InternalLink.value.href}>
                                              {sublink.fields.Title.value}
                                            </NavLink>
                                          )}
                                        </li>
                                      ))}
                                  </ul>
                                </>
                              )}

                              {/* Sub Menu For CategoriesList */}
                              {submenu.fields.CategoriesList && submenu.fields.CategoriesList.length > 0 && (
                                <>
                                  {submenu.fields.Title.value != '' && (
                                    <a className="collapsed" data-toggle="collapse" data-target={`#subind_${subindex}`}>
                                      <Text field={submenu.fields.Title} />
                                    </a>
                                  )}
                                  <ul className="sub-menu slicknav_hidden collapse" id={`subind_${subindex}`}>
                                    {submenu.fields.CategoriesList &&
                                      submenu.fields.CategoriesList.map((sublink, sublinkindex) => (
                                        <li className="menu-item tiret__custom " key={sublinkindex}>
                                          <a
                                            className="cat_nav "
                                            onClick={this.setSearchState.bind(this, sublink, submenu.fields && submenu.fields.ProductPageLink)}>
                                            <Text field={sublink.fields && sublink.fields.title} />
                                          </a>
                                        </li>
                                      ))}
                                  </ul>
                                </>
                              )}
                            </div>
                          </li>
                        ))}
                    </ul>
                  )}
                </li>
              ))}
          </ul>
        </div>

        <div className="col-md-12 custom__height">
          <NavLink to="/" className="logo">
            <img loading="lazy" src={this.props.fields.SiteLogo && this.props.fields.SiteLogo.value.src} className="img_custom_full" />
          </NavLink>
        </div>

        {/* Desktop Menu */}
        <div className="col-md-12 nav-cn">
          <nav>
            <ul className="menu-hd">
              {this.props.fields.HeaderMenu &&
                this.props.fields.HeaderMenu.map((mainMenu, mainindex) => (
                  <li
                    className={` ${mainMenu.fields.SubMenuLinks.length > 0 ? 'menu-item-has-children ' : ''} ${
                      mainindex > 4 ? 'align-right ' : ''
                    } main-parent`}
                    key={mainindex}>
                    {mainMenu.fields.Title.value != '' && mainMenu.fields.Title.value && (
                      <NavLink
                        onClick={() => handleNavigationClick('header', mainMenu.fields.Title.value)}
                        to={mainMenu.fields.InternalLink.value.href}>
                        {mainMenu.fields.Title.value}
                      </NavLink>
                    )}
                    {mainMenu.fields.SubMenuLinks.length > 0 && (
                      <ul className="sub-menu first_level">
                        {mainMenu.fields.SubMenuLinks &&
                          mainMenu.fields.SubMenuLinks.map((submenu, subindex) => (
                            <li
                              className={`menu-item menu-item-has-children ${
                                mainMenu.fields.SubMenuLinks.length == subindex + 1 ? 'no_border__custom' : ''
                              }`}
                              key={subindex}>
                              {submenu.fields.Title.value != '' &&
                                (submenu.fields.InternalLink.value.href != '' ? (
                                  <NavLink to={submenu.fields.InternalLink.value.href}>{submenu.fields.Title.value}</NavLink>
                                ) : (
                                  <div>{submenu.fields.Title.value}</div>
                                ))}
                              {submenu.fields.SubMenuLinks && submenu.fields.SubMenuLinks.length > 0 && (
                                <ul className="sub-menu second_level">
                                  {submenu.fields.SubMenuLinks &&
                                    submenu.fields.SubMenuLinks.map((sublink, sublinkindex) => (
                                      <li className="menu-item" key={sublinkindex}>
                                        {sublink.fields.Title.value != '' && sublink.fields.Title.value && (
                                          <NavLink
                                            onClick={() =>
                                              handleNavigationClick('header', mainMenu.fields.Title.value + '::' + sublink.fields.Title.value)
                                            }
                                            to={sublink.fields.InternalLink.value.href}>
                                            {sublink.fields.Title.value}
                                          </NavLink>
                                        )}
                                      </li>
                                    ))}
                                </ul>
                              )}
                              {/* Sub Menu For CategoriesList */}
                              {submenu.fields.CategoriesList && submenu.fields.CategoriesList.length > 0 && (
                                <>
                                  <ul className="sub-menu">
                                    {submenu.fields.CategoriesList &&
                                      submenu.fields.CategoriesList.map((sublink, sublinkindex) => (
                                        <li className="menu-item" key={sublinkindex}>
                                          {submenu.fields.InternalLink && submenu.fields.InternalLink.value && submenu.fields.Title.value && (
                                            <a className="cat_nav " onClick={this.setSearchState.bind(this, sublink, submenu.fields.ProductPageLink)}>
                                              <Text field={sublink.fields && sublink.fields.title} />
                                            </a>
                                          )}
                                        </li>
                                      ))}
                                  </ul>
                                </>
                              )}
                            </li>
                          ))}
                      </ul>
                    )}
                  </li>
                ))}
            </ul>
          </nav>
        </div>
      </div>
    );
  }
}

export default HeaderMultilevel;
