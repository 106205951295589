import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

const CategoriesList = (props) => {

  return (
          <div className="section mt-3 mb-10">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="product-categories-grid grid-style-1 grid-gutter">
                    <div className="product-categories-grid-wrap row">
                      <Placeholder name="phCategories" rendering={props.rendering} />
                      <div className="product-category-wall wall-row">
                        <Placeholder name="phCategoriesGrid" rendering={props.rendering} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

  );
 
};

export default CategoriesList;
