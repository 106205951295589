import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

class RichTextComponent extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    let text = this.props.fields !== undefined ? this.props.fields.Text : "";
    let variant = this.props.fields !== undefined ? this.props.fields.designVariant.value : "";

    return(
      <div className={"row_custom_small RichTextComponent container " + variant}>
        <div className="col-md-12 text-center all-articles__custom richtext_custom">
          <RichText field={text}/>
        </div>
      </div>
    )
  }
}

export default RichTextComponent;
