import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ReactComponent from "../../ReactComponent";
import { loader as gqlLoader } from "graphql.macro";
import { Query } from "react-apollo";
import { DotLoader } from "react-spinners";
import { css } from "@emotion/react";
import i18next from 'i18next';

const ProductReviewsQuery = gqlLoader('./query.graphql');
const override = css`
  margin: auto;
`;

class ProductReviews extends ReactComponent{
  
    state = {
      isLoaded: false
    };  

    render(){
        var productIdValue = this.props.productId !== "" ? this.props.productId.replace(/-/g, "").toLowerCase(): ""; 

        return(
            <Query query={ProductReviewsQuery} variables={{productId: productIdValue, indexName: this.indexName, rootPath: "/sitecore/content/"+this.appName+"/Content/Rating and Review"}}>
              {({ loading, error, data}) => {
                          if (loading)
                              return(
                                //   <div className='sweet-loading' style={{marginTop:'50px', marginBottom: '50px'}}>
                                //       <DotLoader
                                //           css={override}
                                //           sizeUnit={"px"}
                                //           size={50}
                                //           color={"#0a4f91"}
                                //           loading={!this.state.isLoaded}
                                //       />
                                //   </div>
                                <div></div>
                              );
                          if (error) 
                          return (
                              <div>Error: {error.message}</div>
                          );
                          
                          return (
                            <>
                            {data && data.ratingcount.results &&  
                            data.ratingcount.results.totalCount !== parseInt(0) &&
                                <span>({data.ratingcount.results.totalCount} {i18next.t('Reviews')}) </span>
                            }
                            </>
                          );
              
              }}

            </Query>
        );
        
        

    }

}
export default withSitecoreContext()(ProductReviews);
