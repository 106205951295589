import React from 'react';

const StarRatingV2 = ({value}) => {
  let rating = <><i className="fa fa-star" /><i className="fa fa-star" /><i className="fa fa-star" /><i className="fa fa-star" /><i className="fa fa-star" /></>;
  switch(value) {
    case 0:
      rating = <><i className="far fa-star off" /><i className="far fa-star off" /><i className="far fa-star off" /><i className="far fa-star off" /><i className="far fa-star off" /></>;
      break;
    case 1:
      rating = <><i className="fa fa-star" /><i className="far fa-star off" /><i className="far fa-star off" /><i className="far fa-star off" /><i className="far fa-star off" /></>;
      break;
    case 2:
      rating = <><i className="fa fa-star" /><i className="fa fa-star" /><i className="far fa-star off" /><i className="far fa-star off" /><i className="far fa-star off" /></>;
      break;
    case 3:
      rating = <><i className="fa fa-star" /><i className="fa fa-star" /><i className="fa fa-star" /><i className="far fa-star off" /><i className="far fa-star off" /></>;
      break;
    case 4:
      rating = <><i className="fa fa-star" /><i className="fa fa-star" /><i className="fa fa-star" /><i className="fa fa-star" /><i className="far fa-star off" /></>;
      break;
    case 5:
      rating = <><i className="fa fa-star" /><i className="fa fa-star" /><i className="fa fa-star" /><i className="fa fa-star" /><i className="fa fa-star" /></>;
      break;
    default:
      rating = <><i className="fa fa-star" /><i className="fa fa-star" /><i className="fa fa-star" /><i className="fa fa-star" /><i className="fa fa-star" /></>;
      break;
  }
  return(
    <p className="StarRating">
      {rating}
    </p>
  );
  
}

export default StarRatingV2;
