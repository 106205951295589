import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

const Footer = (props) => {
  return(
    <footer id="footer" className="footer">
      <Placeholder name="phNewsletter" rendering={props.rendering} />   
      <div className="footer-widget">
        <div className="container">
          <div className="footer-widget-wrap">
            <div className="row">
              <Placeholder name="phBottomMenu" rendering={props.rendering} />   
              <div className="footer-widget-col col-md-3 col-sm-6 wow fadeInUp">
                <Placeholder name="phRecentPosts" rendering={props.rendering} />
              </div>    
            </div>   
          </div>
        </div>
      </div>
      <Placeholder name="phBottomBanner" rendering={props.rendering} />     
    </footer>  
  );
};

export default Footer;
