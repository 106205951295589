import React from 'react';
import { NavLink } from "react-router-dom";
import { Text } from '@sitecore-jss/sitecore-jss-react';

const CategoriesNavigation = (props) => {
  const designVariant = typeof props.fields.designVariant === 'undefined' ? "" : props.fields.designVariant.value;
  const {categoriesNavigationList} = props.fields;

  return (     
          <div className={"widget "+designVariant+" commerce widget_product_categories"}>
            <h4 className="widget-title"><Text field={props.fields.title} /></h4>
            <ul className="product-categories">
              { categoriesNavigationList &&    
                categoriesNavigationList.map((listItem, index) => (
                  <li key={`sharedListItem-${index}`} >
                    <NavLink to={"/filteredProducts/?categoryId="+ listItem.id} >{listItem.fields.title.value}</NavLink>
                  </li>
              ))}     
            </ul>
          </div>
  );
}

export default CategoriesNavigation;
