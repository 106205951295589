import React from 'react';
import { NavLink } from "react-router-dom";
import { Image, Text, RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Moment from 'react-moment';
import { Query } from "react-apollo";
import ReactComponent from "../../ReactComponent";
import {loader as gqlLoader} from "graphql.macro";



const PostContentQuery = gqlLoader('./PostContentQuery.graphql');

class PostContent extends ReactComponent {
  

  render() {

    const postContent = this.props.sitecoreContext.route.fields;
    const contextItem = this.props.sitecoreContext.route.itemId;

    let postNav = true;
    if (postContent.PostPrevious == null && postContent.PostNext == null) {
      postNav = false;
    }

    return (
      <>
        {postContent &&
          <>
            <div className={"single "}>
              <div className="hentry">
                <div className="hentry-wrap">
                  <div className="entry-featured">
                    <img loading="lazy" src={postContent.PostThumbnailImageBig && postContent.PostThumbnailImageBig.value.src}  />
                  </div>
                  <div className="entry-info">
                    <div className="entry-header">
                      <Text field={postContent.PostTitle} tag="h1" className="entry-title" />
                      <div className="entry-meta icon-meta">
                        <span className="meta-date">
                          <i className="fa fa-calendar"></i>
                        <Query query={PostContentQuery} variables={{cxItem: contextItem}}>  
                        	{({ loading, error, data}) => {
                        		if(loading)
                        			return <span>Loading...</span>
                        		if(error)
                        			return <span>{error.message}</span>
                        		return(
                        			<Moment format="MMM DD, YYYY">
	                                	{data.contextItem.date.value}
	                              	</Moment>
                        		)
                        	}}
                        </Query>
                        </span>
                        <span className="meta-author">
                          <i className="fa fa-user"></i>
                          <NavLink to="#">
                            <Text field={postContent.PostAuthor} />
                          </NavLink>
                        </span>
                        <span className="meta-category">
                          <i className="fa fa-folder-open-o"></i>
                          <NavLink to={"/Posts-Grid/?tagId="+postContent.PostMainTag.id.replace(/-/g, "")}>
                            <Text field={postContent.PostMainTag.fields.TagName} editable={false} />
                          </NavLink>
                        </span>
                        {/* Comments not yet implemented */}
                        {/* <span className="meta-comment">
                          <i className="fa fa-comment-o"></i>
                          <NavLink href="#" className="meta-comments">3 Comments</a>
                        </span> */}
                      </div>
                    </div>
                    <div className="entry-content">
                      <RichText field={postContent.PostContent} />
                    </div>
                    <div className="entry-footer">
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="entry-tags">
                            <span>Tags:&nbsp;</span>
                            {postContent.PostTagsList &&
                              postContent.PostTagsList.map((tag, index) => {
                                  return (
                                    <NavLink key={index} to={"/Posts-Grid/?tagId="+tag.id.replace(/-/g, "")} key={tag.id}>
                                      <Text field={tag.fields.TagName} editable={false} />
                                    </NavLink>
                                  );
                              })
                            }
                          </div>
                        </div>
                        {/* Social links component */}
                         <div className="col-sm-6">
                          <div className="share-links">
                            <span>Share:</span>
                            <div className="share-icons">
                              <span className="facebook-share">
                                <a href="#" title="Share on Facebook">
                                  <i className="fa fa-facebook"></i>
                                </a>
                              </span>
                              <span className="twitter-share">
                                <a href="#" title="Share on Twitter">
                                  <i className="fa fa-twitter"></i>
                                </a>
                              </span>
                              <span className="google-plus-share">
                                <a href="#" title="Share on Google +">
                                  <i className="fa fa-google-plus"></i>
                                </a>
                              </span>
                              <span className="linkedin-share">
                                <a href="#" title="Share on Linked In">
                                  <i className="fa fa-linkedin"></i>
                                </a>
                              </span>
                            </div>
                          </div>
                        </div> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {postNav &&
                <nav className="post-navigation">
                  <div className="row">
                    {postContent.PostPrevious &&
                      <div className="col-sm-6">
                        <div className="prev-post">
                          <span>Previous article </span>
                          <NavLink to={postContent.PostPrevious.url} rel="prev">{postContent.PostPrevious.fields.PostTitle.value}</NavLink>
                        </div>
                      </div>
                    }
                    {postContent.PostNext &&
                      <div className="col-sm-6">
                        <div className="next-post">
                          <span>Next article </span>
                          <NavLink to={postContent.PostNext.url} rel="next">{postContent.PostNext.fields.PostTitle.value}</NavLink>
                        </div>
                      </div>
                    }
                  </div>
                </nav>
              }
            </div>
          </>
        }
      </>
    );
  }

};

export default withSitecoreContext()(PostContent);
