import React from 'react';

const StarRating = ({value}) => {
  let rating = <span style={{width:'0%'}}></span>;
  switch(value) {
    case 0:
      rating = <span style={{width:'0%'}}></span>;
      break;
    case 1:
      rating = <span className="elegant_icon_star" style={{width:'20%'}}></span>;
      break;
    case 2:
      rating = <span className="elegant_icon_star" style={{width:'40%'}}></span>;
      break;
    case 3:
      rating = <span className="elegant_icon_star" style={{width:'60%'}}></span>;
      break;
    case 4:
      rating = <span className="elegant_icon_star" style={{width:'80%'}}></span>;
      break;
    case 5:
      rating = <span className="elegant_icon_star" style={{width:'100%'}}></span>;
      break;
    default:
      rating = <span className="elegant_icon_star" style={{width:'0%'}}></span>;
      break;
  }
  return(
    <div className="star-rating">
      {rating}
    </div>
  );
  
}

export default StarRating;
