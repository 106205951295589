import React from 'react';
import {RichText} from '@sitecore-jss/sitecore-jss-react';

class SubTabs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isActive: true
        };
        this.changeActiveTrue = this.changeActiveTrue.bind(this);
        this.changeActiveFalse = this.changeActiveFalse.bind(this);
    }

    changeActiveTrue() {
        this.setState({
            isActive: true
        });
    }

    changeActiveFalse() {
        this.setState({
            isActive: false
        });
    }

    render() {

        return (
            <React.Fragment>
                <ul className="nav nav-tabs">
                    {this.props.list &&
                        this.props.list.map((listItem, index) => {
                            return (
                                <React.Fragment key={index}>
                                    {
                                        listItem[0] ?
                                            <li className={this.props.isActive ? "active" : null} onClick={this.changeActiveFalse}>
                                                <a data-toggle="tab" href={`#tab-${this.props.list[0].id.substr(0, 5)}tab`} >
                                                    <RichText field={listItem.fields && listItem.fields.Title}/>
                                                </a>
                                            </li>
                                            :
                                            <li onClick={this.changeActiveFalse}>
                                                <a data-toggle="tab" href={`#tab-${this.props.list[index].id.substr(0, 5)}tab`} >
                                                    <RichText field={listItem.fields && listItem.fields.Title}/>
                                                </a>
                                            </li>
                                    }
                                </React.Fragment>
                            )
                        })
                    }
                </ul>
                <div className="tab-content">
                    {this.props.list &&
                        this.props.list.map((listItem, index) => {
                            return (
                                <React.Fragment key={index}>
                                    {
                                        listItem[0] ?
                                            <div className={this.props.isActive ? "tab-pane fade in active" : "tab-pane fade in"}
                                                 id={`tab-${this.props.list[0].id.substr(0, 5)}tab`} className="tab-pane fade in">
                                                <RichText field={listItem.fields && listItem.fields.Content} tag={"p"}/>
                                            </div>
                                            :
                                            <div className={this.props.isActive ? "tab-pane fade in active" : "tab-pane fade in"}
                                                 id={`tab-${this.props.list[index].id.substr(0, 5)}tab`} className="tab-pane fade in">
                                                <RichText field={listItem.fields && listItem.fields.Content} tag={"p"}/>
                                            </div>
                                    }
                                </React.Fragment>
                            )
                        })
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default SubTabs;
