import React from 'react';
import { NavLink } from "react-router-dom";
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { loader as gqlLoader } from 'graphql.macro';
import ReactComponent from "../../ReactComponent";
import { Query } from "react-apollo";
import { DotLoader } from 'react-spinners';
import { css } from '@emotion/react';

const override = css`
  margin: auto;
`;

const tagsListQuery = gqlLoader('./tagsListQuery.graphql');

class TagsList extends ReactComponent {


render() {
	return(
	    <Query query={tagsListQuery} variables={{path: "/sitecore/content/"+this.appName+"/Content/Post Tags/"}}>
		    {({ loading, error, data }) => {
		      if (loading) return (
		        <div className='sweet-loading' style={{marginTop:'50px', marginBottom: '50px'}}>
		        	loading...
		        </div>
		      );
		      if (error) return `Error! ${error.message}`;
		      return (
		        <div className="widget widget_tag_cloud">
		          <Text field={this.props.fields.title} tag="h4" className="widget-title" />
		          <div className="tagcloud">
		            {data.item.children &&
		              data.item.children.map((tag, index) => (
		                <NavLink key={index} to={"/Posts-Grid/?tagId="+tag.id} key={tag.id}>{tag.tagName.value}</NavLink>
		              ))
		            }
		          </div>
		        </div>
		      );
		    }}
		</Query>
  )
};

}

export default withSitecoreContext()(TagsList);
