import React from 'react';
import {Placeholder} from '@sitecore-jss/sitecore-jss-react';

const PostLayoutWrapper = (props) => {


  return(

    <div className="container">
      <div className="row">
        <div className="col-md-9 col-sm-12 main-wrap">
          <div className="main-content">
            <Placeholder name="main-content" rendering={props.rendering} />
          </div>
        </div>
        <div className="col-md-3 col-sm-12 sidebar-wrap">
          <div className="main-sidebar no-border">
            <Placeholder name="sidebar-right" rendering={props.rendering} />
          </div>  
        </div>
      </div>
    </div>

  );
};

export default PostLayoutWrapper;
