import React from 'react';
import {RichText, Text} from "@sitecore-jss/sitecore-jss-react";
import './headerRollOver.css';

class HeaderRollOver extends React.Component {

    constructor(props) {
        super(props);
    }

    smoothSc() {
        const top = document.getElementById("page-header").offsetTop;
        const height = document.getElementById("page-header").offsetHeight;

        window.scrollTo({top: top + height, behavior: 'smooth'});
    }

    render() {
        let bgImageSrc = this.props.fields.Background && this.props.fields.Background.value.src !== "" ? this.props.fields.Background.value.src : "";
        const bgImage = {
            backgroundImage: 'url(' + bgImageSrc + ')'
        }; 

        return (
            <div style={bgImage} className="page-header" id="page-header">
                <div className="content">
                    <div className="overlay">
                        <div className="discoverHead">
                            <RichText field={this.props.fields && this.props.fields.Title}
                                      className="page-title ops-scroll-animation ops-in-view"
                                      tag={"h1"}
                            />
                            <RichText field={this.props.fields && this.props.fields.Subtitle} tag={"span"} className="subtitle"/>
                        </div>
                        <div className="discover">
                            <Text field={this.props.fields && this.props.fields.Button}
                                  className="smooth-scroll"
                                  tag={"a"}
                                  onClick={this.smoothSc}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HeaderRollOver;
