import React from 'react';
import { NavLink } from "react-router-dom";
import { Text, Image, RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { DotLoader } from 'react-spinners';
import { css } from '@emotion/react';
import { Query } from "react-apollo";
import ReactComponent from "../../ReactComponent";
import {loader as gqlLoader} from "graphql.macro";

const override = css`
  margin: auto;
`;

const FeaturedPostsDynamicQuery = gqlLoader('./FeaturedPostsDynamicQuery.graphql');


class FeaturedPostsDynamic extends ReactComponent {


  render() {
    const {FeaturedPostsContentList} = this.props.fields;
    const designVariant = typeof this.props.fields.designVariant === 'undefined' ? "" : this.props.fields.designVariant.value;

      return(          
        <div className={"FeaturedPostsDynamic" + designVariant}>
        <div className="section pt-7" >
          <div className="container">
            <div className="row">
              <div data-wow-delay="0.5s" className="col-md-12 col-sm-12 wow fadeInUp">
                <h2 className="text-center mb-2" ><Text field={this.props.fields.Title} /></h2>
                <p className="text-center mb-4" ><Text field={this.props.fields.Text} /></p> 
                <div className="separator-inherit-theme" >
                  <hr className="mini-sep" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Query query={FeaturedPostsDynamicQuery} variables={{indexname: this.indexName, rootPath: "sitecore/content/"+this.appName+"/"}}>
	        {({ loading, error, data}) => {
	        	if(loading)
	        		return(
	        			<div className="section mt-2 pb-4">
				            <div className="container">
				              <div className="row">
				                <div className='sweet-loading' style={{marginTop:'50px', marginBottom: '50px'}}>
				                  <DotLoader
				                    css={override}
				                    sizeUnit={"px"}
				                    size={50}
				                    color={'#9fce4e'}
				                    loading={loading}
				                  />
				                </div>
				              </div>
				            </div>
				        </div>
	        		);
	        	if(error)
	        		return(
	        			<div className="section mt-2 pb-4">
				            <div className="container">
				              <div className="row">
				                Error: {error.message}
				              </div>
				            </div>
				        </div>
	        		);
	        	return(
		        <div className="section mt-2 pb-4">
		          <div className="container">
		            <div className="row">
		              {FeaturedPostsContentList &&    
		                FeaturedPostsContentList.map((listItem, index) => (
		                  ( data.search.results.items &&
		                    data.search.results.items.map((post) => {
		                      var id = listItem.id.split('-').join('').toUpperCase();
		                      if(id === post.item.id) {
		                        return (
		                          <NavLink key={`sharedListItem-${index}`} to={post.item.url}>
		                            <div className="col-md-4 col-sm-12 wow fadeInUp" >
		                              <div className="text-center" >
		                                <img loading="lazy" src={listItem.fields.PostThumbnailImageMedium && listItem.fields.PostThumbnailImageMedium.value.src}/>
		                              </div>              
		                              <h4 className="text-center mt-2 mb-2">
		                                <Text field={listItem.fields.PostTitle} />        
		                              </h4>
		                              <div className="text-center"><RichText field={listItem.fields.PostShortDescription} /></div>
		                            </div>
		                          </NavLink>         
		                        );
		                      }
		                      return false;
		                    })
		                  )                  
		                ))
		              } 
		            </div>
		          </div>
		        </div>
		        )
	    	}}
	    </Query>
    </div>             
      );
    
  }

}

export default withSitecoreContext()(FeaturedPostsDynamic);
