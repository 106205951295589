import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import Masonry from 'react-masonry-component';
import './MasonryArticle.css'
import $ from 'jquery/dist/jquery';

class MasonryArticle extends React.Component {

	componentDidMount() {

		/* Scroll animations */

        var $window = $(window);
		
        function check_if_in_view() {
			var $animation_elements = $('.ops-js-scroll-animation');
            var window_height = $window.height();
            var window_top_position = $window.scrollTop();
            var window_bottom_position = (window_top_position + window_height);
            var window_top_third_position = (window_top_position + (window_height * 0.9));
			var count = 1;

            $.each($animation_elements, function() { 
				
				var $element = $(this);
				// var element_height = $element.outerHeight();
                var element_top_position = $element.offset().top;
                //var element_bottom_position = (element_top_position + element_height);

                //check to see if this current container is within viewport
                if (element_top_position <= window_top_third_position || element_top_position <= window_bottom_position - (window_bottom_position*0.075)) { // add (element_bottom_position >= window_top_position) if also animation scroll to top
                    $element.addClass('ops-in-view');
                } else {
                    $element.removeClass('ops-in-view');
                }
            });
        }

        check_if_in_view();
        $window.on('scroll resize', check_if_in_view);
        $window.trigger('scroll');
	}

	handleCompletion() {
		document.querySelectorAll(".node-type-page").forEach(itm => itm.style.opacity = '1');
	}

	render(){

		const masonryOptions = {
		    itemSelector: '.o-js-masonry-item',
		    columnWidth: 0
		};

		const blocks = this.props.fields.Blocks;

		return(
			
			<div className="node-type-page">
				<Masonry 
					className="masonry_ctn node node-page clearfix"  
					onLayoutComplete={this.handleCompletion}
					options={masonryOptions} 
				>
				<div className="o-page-modules">
					<div className="o-list-modules o-js-masonry">

						{/* Loop of blocks */}
						{blocks && blocks.map((blockItem, index) => (
							<div key={index} className="o-list-modules-item o-js-masonry-item ops-js-scroll-animation ops-scroll-animation ops-scroll-animation--from_bottom o-list-modules-item--bundle-title-text">
				                <RichText field={blockItem.fields.Content} editable={false} />
				            </div>
						))}

					</div>
				</div>
				</Masonry>
			</div>
			

			)
}

}


export default MasonryArticle;
