import React from 'react';
import {Placeholder, Text, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import ReactComponent from "../../ReactComponent";
import fetch from 'isomorphic-fetch';

class ArticleLayoutWrapper extends ReactComponent {

  componentDidMount(){
    // GTM informations
    fetch(this.getGraphqlEndpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ query: `query ($indexname: String!, $articleId: String!) {
        article: search(index: $indexname,
           fieldsEqual: [{
            name: "_group",
            value: $articleId}],
          ) {
          results {
            items {
              item {
                 dateUpdate: field(name: "__Updated") {
                              value
                          }
                 dateCreate: field(name: "__Created") {
                              value
                          }
                parent{
                  name
                }
              }
            }
          }
        }
      }`, variables: {indexname: this.indexName, articleId: this.props.sitecoreContext.itemId.replace(/-/g, '') } }),
    })
      .then(res => res.json())
      .then(res => {
          
            window.dataLayer.push({
              'articleName':this.props.sitecoreContext.route.fields.articleTitle.value,
              'articleCategory':res.data.article.results.items[0].item.parent.name,
              'publicationMonth':res.data.article.results.items[0].item.dateCreate.value.substring(4,6),
              'publicationYear':res.data.article.results.items[0].item.dateCreate.value.substring(0,4)
            })
          
        }
        );
    
    

  }
  render(){

    return(
      <div className="container">
        <div className="row row-article-layout">
          <div className="col-md-12 text-left populaireLight custom__title_article">
              <Text field={this.props.sitecoreContext.route.fields.articleTitle} tag="h1"/>
          </div>
        </div>
        <div className="row row-article-layout">
            <div className="col-md-8">
                <Placeholder name="article-main-content" rendering={this.props.rendering} />
            </div>
            <div className="col-md-4">
                <Placeholder name="article-sidebar-right" rendering={this.props.rendering}/>
            </div>
        </div>
    </div>
    )
  }
}
export default withSitecoreContext()(ArticleLayoutWrapper);
