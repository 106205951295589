import React from 'react';
import { NavLink } from "react-router-dom";
import { Text, Link } from '@sitecore-jss/sitecore-jss-react';

const SubMenu = (props) =>  {
  const subMenuContentList = props.subMenuContentList;
  return (
            <div data-wow-delay="0.6s" className="footer-widget-col col-md-3 col-sm-6 wow fadeInUp">
              <div className="widget">
                <h3 className="widget-title"><span><Text field={props.title} /></span></h3> 
                <div className="textwidget">
                  <ul className="address">
                    {subMenuContentList &&    
                      subMenuContentList.map((listItem, index) => (
                      <li key={`sharedListItem-${index}`} >       
                        <NavLink to={listItem.fields.InternalLink.value.href}>{listItem.fields.InternalLink.value.text}</NavLink>
                      </li>
                    ))} 
                  </ul>
                </div>
              </div>
            </div>
          );
}
export default SubMenu;
