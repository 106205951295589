import React from 'react';
import { Text,withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ParentRoute from '../../utils/ParentRoute';

const Breadcrumbs = (props) => {
  return(
      <div className={"row_custom_small display__bread "}>
        <div className="col-md-12 pt-0 pb-0 helv_md container-bread">
          <ParentRoute CurrentId={props.sitecoreContext.itemId} CurrentItemName={props.sitecoreContext.route.displayName} />
        </div>
      </div>
    );
}
export default withSitecoreContext()(Breadcrumbs);
