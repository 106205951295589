import React from 'react';
import {RichText, Text, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import './videoFullScreen.css';
import {NavLink} from "react-router-dom";

class VideoFullScreenWithLayer extends React.Component {

    constructor(props) {
        super(props);
    }

    render(){
        return (
            <div className="video-background-holder">
                <video playsInline="playsinline" autoPlay="autoplay" muted="muted" loop="loop">
                    <source src={this.props.fields && this.props.fields.Video.value.href} type="video/mp4" />
                </video>
                <div className="video-background-content container h-100">
                    <div className="d-flex h-100 text-center align-items-center">
                        <div className="w-100 text-white text-left">
                            <RichText field={this.props.fields && this.props.fields.Title} className="display-4"/>
                            <RichText field={this.props.fields && this.props.fields.Subtitle} className="lead mb-2" tag={"p"} />
                            {
                                this.props.fields.Button1.value.href !== '' &&
                                    <NavLink to={this.props.fields && this.props.fields.Button1.value.href} className="btn btn-white-outline btn-align-left btn-icon btn-icon-slide-in btn-icon-right wow fadeInRight">
                                        <span>{this.props.fields && this.props.fields.Button1.value.text}</span>
                                        <i className="fa fa-long-arrow-right"></i>
                                    </NavLink>
                            }
                            {
                                this.props.fields.Button2.value.href !== '' &&
                                    <NavLink to={this.props.fields && this.props.fields.Button2.value.href} className="btn btn-white-outline btn-align-left btn-icon btn-icon-slide-in btn-icon-right wow fadeInRight">
                                        <span>{this.props.fields && this.props.fields.Button2.value.text}</span>
                                        <i className="fa fa-long-arrow-right"></i>
                                    </NavLink>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withSitecoreContext()(VideoFullScreenWithLayer);
