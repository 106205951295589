import React from 'react';
import { NavLink } from "react-router-dom";
import { Text,Link } from '@sitecore-jss/sitecore-jss-react';


const FooterMenu = (props) => {
  const {SiteLanguages,footerMenuLinks} = props.fields;
  const designVariant = typeof props.fields.designVariant === 'undefined' ? "" : props.fields.designVariant.value;

  return (
    <div className={"content__wrapper "+designVariant}>
            <footer>
                <div className="content__footer">
                    <div className="content__footer-language-selector">
                        <select name="changeLanguage" id="changeLanguage">
                            
                            { SiteLanguages &&    
                              SiteLanguages.map((language, index) => (
                              <option key={`option-${index}`} value={`${language.fields.LanguageTitle.value}`} >
                                  {language.fields.LanguageTitle.value}
                              </option>
                            ))}

                        </select>
                    </div>
                    <ul className="content__footer-menu">
                        { footerMenuLinks &&    
                          footerMenuLinks.map((listItem, index) => (
                          <li key={`footermenuitem-${index}`} >
                              {listItem && listItem.fields && listItem.fields.InternalLink && listItem.fields.InternalLink.value && listItem.fields.InternalLink.value.href && listItem.fields.InternalLink.value.href.startsWith("http") === true ?
                              <Link field={listItem.fields.InternalLink} key={index} />
                              :
                              <NavLink to={listItem.fields.InternalLink.value.href}>
                                {listItem.fields.InternalLink.value.text}
                              </NavLink>                                                             
                              }                              
                          </li>
                        ))} 
                        
                    </ul>
                </div>
            </footer>
        </div>
  );
}


export default FooterMenu;
