import React from 'react';
import { NavLink } from "react-router-dom";
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Moment from 'react-moment';
import Paging from '../../utils/paging.js';
import ReactComponent from "../../ReactComponent";

class PostsList extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      itemdata: []
    };
    this.onItemsChange = this.onItemsChange.bind(this);
  }

  onItemsChange(itemdata) {
    this.setState({itemdata: itemdata});
  }

  render() {
    var QUERY = 'query={ search( index:"'+ this.indexName +'" first: ##first, after: "##after", fieldsEqual: [{name: "_templatename", value: "PostRoute"}], rootItem: "/sitecore/content/'+ this.appName +'/") ' +
        '{ results { totalCount pageInfo { startCursor endCursor hasNextPage hasPreviousPage } items { ' +
        'item { url date: field(name: "__Updated") { value } ' +
        '... on PostRoute { postTitle { value } postShortDescription { value } postThumbnailImageBig { src value } postMainTag { targetItem { ' +
        '...on PostTag { id tagName {value } } } } postAuthor { value } } } } } } }';

    const itemdata = this.state.itemdata;

    return(
        <div className="posts" data-layout="default">
          <div className="posts-wrap posts-layout-default">
            {itemdata.data && itemdata.data.search.results.items.map((post, index) => (
                <div className="hentry" key={`post-${index}`}>
                  <div className="hentry-wrap">
                    <div className="entry-featured">
                      <NavLink to={post.item.url}>
                        <img loading="lazy" src={post.item.postThumbnailImageBig.src} alt="" />
                      </NavLink>
                    </div>
                    <div className="entry-info">
                      <div className="entry-header">
                        <h2 className="entry-title">
                          <NavLink to={post.item.url}>{post.item.postTitle.value} </NavLink>
                        </h2>
                      </div>
                      <div className="entry-meta icon-meta">
                    <span className="meta-date">
                      <i className="fa fa-calendar"></i>
                      <span>
                        <Moment format="MMM DD, YYYY">
                          {post.item.date.value}
                        </Moment>
                      </span>
                    </span>
                        <span className="meta-author">
                      <i className="fa fa-user"></i>
                      <NavLink to="#">{post.item.postAuthor.value}</NavLink>
                    </span>
                        {post.item.postMainTag.targetItem && post.item.postMainTag.targetItem.tagName &&
                          <span className="meta-category">
                            <i className="fa fa-folder-open-o"></i>
                            <NavLink to={"/Posts-Grid/?tagId="+post.item.postMainTag.targetItem.id}>{post.item.postMainTag.targetItem.tagName.value}</NavLink>
                          </span>
                        }
                      </div>
                      <div className="entry-content">
                        {post.item.postShortDescription.value}
                      </div>
                      <div className="clearfix">
                        <div className="readmore-link">
                          <NavLink to={post.item.url}>Read More</NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            ))}
          </div>
          <Paging onItemsChange={this.onItemsChange} query={QUERY} itemsPerPage={this.props.fields.postPerPage.value} />
        </div>
    );
  }
}

export default withSitecoreContext()(PostsList);
