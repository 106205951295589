import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import { RichText, Image } from '@sitecore-jss/sitecore-jss-react';
import 'react-owl-carousel2/src/owl.carousel.css';
import './SideBySideCarousel.css';

class SideBySideCarousel extends React.Component {

  render(){

    let options = {
      items: 1,
      loop: false,
      dots: true,
      dotsData: true,
      autoplay: false,
      mouseDrag: false,
      touchDrag: true,
      animateOut: 'fadeOut',
    };
    
    const Slides = this.props.fields.Slides;

    return(

      <div className={"SideBySideCarousel " + this.props.fields.designVariant.value}> 
        <OwlCarousel options={options}>
          {Slides && Slides.map((slideItem, index) => (
            <div key={index} className="slideItem" data-dot={index+1}>  
              <div className="side leftPart">
                <div className="imgContent" style={{backgroundImage: `url(`+slideItem.fields.Image.value.src+`)`}}></div>
              </div>
              <div className={((index+1)%2 == 0)? "side rightPart pair" : "side rightPart"}>
                <div className="containerBorder">
                  <div className="number">{index+1}</div>
                  <div className="title"><RichText field={slideItem.fields.Title}/></div>
                  <img loading="lazy" src={slideItem.fields.Icone && slideItem.fields.Icone.value.src}/>
                  <div className="text"><RichText field={slideItem.fields.Texte}/></div>
                </div>
              </div>
            </div>
          ))}
        </OwlCarousel>
      </div>
    )
  }
  
};

export default SideBySideCarousel;
