import React from 'react';
import $ from 'jquery/dist/jquery';
import './goToTop.css';

class GoToTop extends React.Component {
  
  componentDidMount() {
    //Events on scroll
    $(window).scroll(function() {
      //go to top
      if ($(this).scrollTop() > 500) {
          $(".go-to-top").addClass("on");
      } else {
          $(".go-to-top").removeClass("on");
      }
    });

    $("body").unbind().on("click", ".go-to-top", function() {
      console.log("why");
      
        $("html, body").animate({
            scrollTop: 0
        }, 800);
        return false;
    });
  }

  render() {
    return(
      <a href="#" className="go-to-top"><i className="fa fa-angle-up"></i></a>
    );
  }
}

export default GoToTop;
