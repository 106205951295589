import React from 'react';
import { NavLink } from "react-router-dom";
import { Text, Link } from '@sitecore-jss/sitecore-jss-react';

const BottomBanner = (props) => {
  const {bottomBannerContentList} = props.fields;
  const designVariant = typeof props.fields.designVariant === 'undefined' ? "" : props.fields.designVariant.value;

  return (
          <div data-wow-delay="0.3s" className={"footer-info clearfix wow fadeInUp "+designVariant}>
            <div className="container">
              <div className="row">
                <div className="col-sm-6">
                  <div className="footer-copyright">
                  <Text field={props.fields.description} />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="footer-menu">
                    <ul className="footer-nav">
                    { bottomBannerContentList &&    
                      bottomBannerContentList.map((listItem, index) => (
                      <li key={`sharedListItem-${index}`} >
                          {listItem && listItem.fields && listItem.fields.InternalLink && listItem.fields.InternalLink.value && listItem.fields.InternalLink.value.href.startsWith("http") === true ? 
                          <Link field={listItem.fields.InternalLink}>{listItem.fields.InternalLink.value.text}</Link>
                          :
                          <NavLink to={listItem.fields.InternalLink.value.href}>{listItem.fields.InternalLink.value.text}</NavLink>
                          }
                          
                      </li>
                    ))}                
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>        
  );
}

export default BottomBanner;
