import React from 'react';
import { NavLink } from "react-router-dom";
import { Text, Image, Link } from '@sitecore-jss/sitecore-jss-react';
import './Block-ImageLeft.css';

const BlockImageLeft = (props) => (
  <div className={"section parallax-background-3 "+props.fields.designVariant.value+""} style={{ backgroundImage: `url(`+props.fields.BackgroundImage.value.src+`)` }}>
    <div className="container">
      <div className="row">
        <div className="col-sm-6 col-lg-6 col-md-6 col-sm-12 wow fadeInLeft">
          <div className="image">
            <img loading="lazy" src={props.fields.Image && props.fields.Image.value.src}/>
          </div>
        </div>
        <div className="col-sm-12 col-lg-6 col-md-6 pt-3 pb-5">
          <Text data-wow-delay="0.3s" field={props.fields.Title} tag="h2" className="white mb-0 wow fadeInRight" />
          <Text data-wow-delay="0.4s" tag="p" className="white mt-3 mb-5 wow fadeInRight" field={props.fields.Description} />
          {props && props.fields && props.fields.Link && props.fields.Link.value && props.fields.Link.value.href.startsWith("http") === true ? 
          <Link field={props.fields.Link} className="btn_custom_oro" />
          :
          <NavLink to={props.fields.Link.value.href} className="btn_custom_oro">
            <span>{props.fields.Link.value.title}</span>
          </NavLink> 
          } 
        </div>
      </div>
    </div>
  </div>
);

export default BlockImageLeft;

