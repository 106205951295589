import React from 'react';
import {Placeholder,withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import ReactComponent from "../../ReactComponent";

class StoreLocatorLayoutWrapper extends ReactComponent {

  constructor(props) {
    super(props);

    this.state = {
      contextdata: this.props,
    }
  }

  render() {
    return(
        <Placeholder name="storelocator-ph" indexName={this.indexName} rendering={this.props.rendering}  />
    );
  }
}

export default withSitecoreContext()(StoreLocatorLayoutWrapper);
