import React from 'react';
import { Image, Text, RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './titleAndDescAndImg.css';

const BioTitleAndDescriptionAndImage = (props) => {
    return (
        <div className={"row_custom_small box-1 "+props.fields.designVariant.value}>
            <div className="row_custom_small section3">
              <div className="col-md-12 pad text-center">
                  <font color={props.fields.TitleColor && props.fields.TitleColor.fields.cssColor.value}>
                  {props.fields.Title && props.fields.Title.value !== "" && <Text field={props.fields.Title} tag="h1" className={"col-md-12"}/>}
                  {props.fields.ImageLocation && props.fields.Image && props.fields.ImageLocation.fields.title.value == 'Before Description' && (
                    <img loading="lazy" src={props.fields.Image && props.fields.Image.value.src} className="img_custom_full"/>
                  )}
                  {props.fields.Description && props.fields.Description.value !== "" && <blockquote> <RichText field={props.fields.Description} tag="p"/> </blockquote> }
                  </font>
                  {props.fields.ImageLocation && props.fields.Image && props.fields.ImageLocation.fields.title.value !== 'Before Description' && (
                    <img loading="lazy" src={props.fields.Image && props.fields.Image.value.src}  className="img_custom_full"/>
                  )}                  
                </div>
            </div>
        </div>
    );
};

export default withSitecoreContext()(BioTitleAndDescriptionAndImage);
