import React from 'react';
import { loader as gqlLoader } from 'graphql.macro';
import { Query } from "react-apollo";
import {geolocated} from 'react-geolocated';
import locationIcon from '../../assets/images/Storelocator/whiteLocation2.png';
import i18next from 'i18next';
import './StoreLocator.css';
import packageJson from '../../../package.json';

const StoreLocatorQuery = gqlLoader('./StoreLocator.graphql');
const _ = require("lodash");
const { compose, withProps, lifecycle, withStateHandlers } = require("recompose");
const { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow,} = require("react-google-maps");
const { SearchBox } = require("react-google-maps/lib/components/places/SearchBox");
const rootpathString = "/sitecore/content/" + packageJson.config.appName + "/Content/StoreLocator/Stores";

function distance(lat1, lon1, lat2, lon2, unit) {
	if ((lat1 == lat2) && (lon1 == lon2)) {
		return 0;
	}
	else {
		var radlat1 = Math.PI * lat1/180;
		var radlat2 = Math.PI * lat2/180;
		var theta = lon1-lon2;
		var radtheta = Math.PI * theta/180;
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180/Math.PI;
		dist = dist * 60 * 1.1515;
		if (unit=="K") { dist = dist * 1.609344 }
    if (unit=="N") { dist = dist * 0.8684 }
    return dist;
	}
}

function buy(shoppingServiceUrl, shopUrlParams){
  window.open(shoppingServiceUrl + "" + shopUrlParams, '_blank');
}

function getNearestStores(markers, stores, dist, currentLatitude, currentLongitude) {
  var nearestStores = [];
  if(currentLatitude !== 0 && currentLongitude !== 0){
    stores &&
    stores.map((listItem) => { 
      nearestStores.push([distance(currentLatitude, currentLongitude, Number(listItem.item.latitude.value), Number(listItem.item.longitude.value), "K"), Number(listItem.item.latitude.value), Number(listItem.item.longitude.value), listItem.item.storeName.value, listItem.item.adress.value, listItem.item.phone.value, listItem.item.email.value, listItem.item.storeImage.src, listItem.item.shopUrlParams.value]);       
    });
  }
  else{
    markers.map((marker) =>    
    stores &&
    stores.map((listItem) => { 
      nearestStores.push([distance(marker.position.lat(), marker.position.lng(), Number(listItem.item.latitude.value), Number(listItem.item.longitude.value), "K"), Number(listItem.item.latitude.value), Number(listItem.item.longitude.value), listItem.item.storeName.value, listItem.item.adress.value, listItem.item.phone.value, listItem.item.email.value, listItem.item.storeImage.src, listItem.item.shopUrlParams.value]);       
    })
  );
  }
  nearestStores = nearestStores.filter(function (el) { return (el[0] <= dist); });
  nearestStores = nearestStores.sort((a, b) => a[0] - b[0]);

  if(nearestStores.length > 0 && document.querySelector(".storesListContainer")){
    document.querySelector(".storesListContainer").classList.add("open")
  }
  if(nearestStores.length === 0 && document.querySelector(".storesListContainer")){
    document.querySelector(".storesListContainer").classList.remove("open")
  }
  
  return nearestStores;

}

const MapWithASearchBox = compose(
  withStateHandlers(() => ({
    isOpen: false,
    infoIndex: null,
    state: false,
    hoverIndex: null,
    currentLatitude : 0,
    currentLongitude : 0,
    locationUpdate : false
  }), {
    updateLatLong :({locationUpdate}) => (latitude, longitude) =>  ({  
      currentLatitude : latitude,
      currentLongitude : longitude,
      locationUpdate : !locationUpdate
    }),
    initLatLong :() => () =>  ({  
      currentLatitude : 0,
      currentLongitude : 0
    }),
    showInfo: ({ isOpen, infoIndex }) => (index) => ({    
      isOpen: infoIndex !== index || !isOpen,
      infoIndex: index
    }),
    handleMouse: ({ state, hoverIndex }) => (index) => ({    
      state: hoverIndex !== index || !state,
      hoverIndex: index
    }),
  }),
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?client=gme-lorealsa&channel=CHANNEL_ID_FOR_BRAND&v=quartely&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div className="mapContainer" />,
    mapElement: <div className="mapElement" />,
  }),
  lifecycle({
    UNSAFE_componentWillMount() {
      const refs = {}
      this.setState({
        bounds: null,
        center: {
            lat: 41.9028, lng: 12.4964
        },
        markers: [],
        onMapMounted: ref => {
          refs.map = ref;
          // const bounds = new window.google.maps.LatLngBounds();   
          // refs.map.props.children.forEach((child) => {
          //   if (child.type === Marker) {
          //       bounds.extend(new window.google.maps.LatLng(child.props.position.lat, child.props.position.lng));
          //   }
          // });
          // refs.map.fitBounds(bounds);    
        },
        onBoundsChanged: _.debounce( () => {
          this.setState({
            bounds: refs.map.getBounds(),
            center: refs.map.getCenter(),
          })
          let { onBoundsChange } = this.props
          if (onBoundsChange) {
          onBoundsChange(refs.map)
        }
        },
        100,
        { maxWait: 500 }
        ),
        onSearchBoxMounted: ref => {
          refs.searchBox = ref;
        },
        onPlacesChanged: () => {
          const places = refs.searchBox.getPlaces();
          const bounds = new window.google.maps.LatLngBounds();     

          places.forEach(place => {
            if (place.geometry.viewport) {
              bounds.union(place.geometry.viewport)
            } else {
              bounds.extend(place.geometry.location)
            }
          });     
          const nextMarkers = places.map(place => ({
            position: place.geometry.location,
          }));
          const nextCenter = _.get(nextMarkers, '0.position', this.state.center);

          this.setState({
            center: nextCenter,
            markers: nextMarkers,
          });         
          refs.map.fitBounds(bounds);
        },
      })
    },
  }),
  withScriptjs,
  withGoogleMap
)(props =>  
  <GoogleMap
    ref={props.onMapMounted}
    defaultZoom={11}
    center={props.center}
    onBoundsChanged={props.onBoundsChanged}
  >
    <SearchBox
      ref={props.onSearchBoxMounted}
      bounds={props.bounds}
      controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
      onPlacesChanged={props.onPlacesChanged}
    >
      <div className="searchInput">
        <input
          type="text"
          placeholder="Find your store"
          style={{
            padding:`10px`, 
            border: `none`,
            flexGrow: `2`
          }}
          onClick = {props.initLatLong}
          onKeyPress={event => {
                      if (event.key === 'Enter') {
                        props.initLatLong()
                      }
                    }}
          />        
        <button style={{border: `1px solid #000`, background: `#151515`, color: `white`,  padding:`1px 6px`}} onClick={() => props.updateLatLong(props.currentLat, props.currentLong)}><img loading="lazy" loading="lazy"  src={locationIcon} style={{height : `25px`}}/></button>
      </div>
    </SearchBox>
    <div className="storesListContainer">
      {getNearestStores(props.markers, props.stores, Number(props.distance), props.currentLatitude, props.currentLongitude).map((store, index) =>
        <>
          <div style={{height:`140px`, width: '240px', padding:`10px`, backgroundColor: (props.state && props.hoverIndex === index) ? "#f4f4f4" : "#fff"}} onMouseEnter={() => props.handleMouse(index)} onMouseLeave={() => props.handleMouse(index)}> 
            <a href="javascript:void(0);" onClick={() => props.showInfo(index)}>
              <p><h4>{index + 1}.&nbsp;<span className="glyphicon glyphicon-map-marker"></span>&nbsp;{store[3]}</h4></p>
              <p>
                {store[4]}<br/>          
              </p>
            </a>
            <input type="button" value="Buy"
              style={{               
                color: `white`,
                background: `#4C8FFB`,
                border: `1px #3079ED solid`,
                boxShadow: `inset 0 1px 0 #80B0FB`,
                padding: `6px 15px`,
                borderRadius: `2px`,
                fontWeight: `bold`,
                fontSize: `9pt`,
                outline: `none`,                 
              }}
              onClick={() => buy(props.shoppingServiceUrl, store[8])}
              /> 
            <hr style={{marginBottom:'0'}} />
          </div> 
          <Marker key={index} position={{ lat: store[1], lng: store[2] }}  onClick={() => props.showInfo(index)} 
            icon={{ url: (props.state && props.hoverIndex === index) ? props.markerIconHover : props.markerIcon }} 
            label={(index + 1).toString()} 
            onMouseOver={() => props.handleMouse(index)} onMouseOut={() => props.handleMouse(index)}
            >
            { (props.isOpen && props.infoIndex === index)  && <InfoWindow onCloseClick={props.onToggleOpen}>
                <>
                  <div style={{float:'left', width:'50px', height:'30px'}}>
                    <img loading="lazy" loading="lazy"  src={store[7]} />
                  </div>
                  <div style={{paddingRight:'20px', paddingBottom:'20px'}}>
                    <p><h4>{store[3]}</h4></p>
                    <p>
                      {store[4]}<br/>
                      {store[5]}<br/>
                      {store[6]}<br/>
                      <b>({store[0].toFixed(1)} Km)</b><br/>
                    </p>
                    <input type="button" value="Buy"
                      style={{               
                        color: `white`,
                        background: `#4C8FFB`,
                        border: `1px #3079ED solid`,
                        boxShadow: `inset 0 1px 0 #80B0FB`,
                        padding: `6px 15px`,
                        borderRadius: `2px`,
                        fontWeight: `bold`,
                        fontSize: `9pt`,
                        outline: `none`,                 
                      }}
                      onClick={() => buy(props.shoppingServiceUrl, store[8])}
                    />
                  </div>
                </>
            </InfoWindow>}
          </Marker>
          </>      
        )}
      </div>
    </GoogleMap>
  );

  const StoreLocatorList = (props) => (
    <Query query={StoreLocatorQuery} variables={{ indexname: props.indexName, rootpath: rootpathString }}>
    {({ loading, error, data}) => {
    if (loading) return "Loading...";
    if (error) return `Error! ${error.message}`;
    return (
      <>
      <div style={{ width: `80%`, margin:`auto`, textAlign: `center`}}><h2>{i18next.t('points-of-sale')}</h2></div>
      <MapWithASearchBox stores = {data.search.results.items} distance = {props.distance} shoppingServiceUrl={props.shoppingServiceUrl} markerIcon = {props.markerIcon} markerIconHover = {props.markerIconHover} currentLat = {props.latitude} currentLong = {props.longitude}/>
      </>
  );
  }}
  </Query>
);
class CurrentGeolocation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            indexName: this.props.indexName
        };
    }

    render() {
        return  !this.props.isGeolocationAvailable
                ? <div>{i18next.t('does-not-support-geolocation')}</div>
                : !this.props.isGeolocationEnabled
                ? <div>{i18next.t('geolocation-not-enabled')}</div>
                : this.props.coords
                ? <StoreLocatorList latitude={this.props.coords.latitude && this.props.coords.latitude} longitude={this.props.coords.longitude && this.props.coords.longitude} distance = {this.props.fields.distances.fields.distance.value} shoppingServiceUrl={this.props.fields.shoppingServiceUrl && this.props.fields.shoppingServiceUrl.value} markerIcon = {this.props.fields.markerIcon && this.props.fields.markerIcon.value.src} markerIconHover = {this.props.fields.markerIconHover && this.props.fields.markerIconHover.value.src}/>
                : <div>{i18next.t('getting-the-location-data')}&hellip; </div>;   
    }
}
export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(CurrentGeolocation);

