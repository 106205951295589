import React from 'react';
import SubMenu from '../SubMenu';

const BottomMenu = (props) =>{
  const designVariant = typeof props.fields.designVariant === 'undefined' ? "" : props.fields.designVariant.value;

  const subMenuComponentList = props.fields.subMenuComponentList;
  return(
      <div className={designVariant}>
        { subMenuComponentList &&
          subMenuComponentList.map((listItem, index) => (
          <div key={`listItem-${index}`}>
            <SubMenu title={listItem.fields.title} subMenuContentList={listItem.fields.subMenuContentList} />
          </div>
          ))
        }
      </div>       
  );
};

export default BottomMenu;
