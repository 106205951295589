import React from 'react';
import {Placeholder} from '@sitecore-jss/sitecore-jss-react';

class ProductLayoutWrapper extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      items: [],
    };
    this.onProductChange = this.onProductChange.bind(this);
  }

  onProductChange(items) {
    this.setState({
      items: items,
    })
  }

  render(){
  return (
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 main-wrap">
                <div className="main-content">
                  <div className="style-2 product">
                  <Placeholder name="main-content" rendering={this.props.rendering} 
                    product={this.state.items} onProductChange={this.onProductChange}
                  />
                  </div>          
                </div>
              </div>
            </div>
          </div>
    );
  }
}
export default ProductLayoutWrapper;
