import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

class CartMessage extends React.Component {
	constructor(props) {
    super(props);
    this.state ={
      cartItems: "",
      productName: "",
      cartmsgclass: ""
    }
  }

  componentDidMount() {
    if(sessionStorage.getItem('cartItems')){
			let sessionItems = sessionStorage.getItem('cartItems')
			let getSessionItems =  sessionItems !== null  ?  JSON.parse(sessionItems) : ""

			if(getSessionItems !== "") {
        this.setState({
          cartItems: getSessionItems
        })

        let idx = getSessionItems.findIndex(i => i.id == this.props.productid);
        if(idx>=0) {
          this.setState({
            productName: getSessionItems[idx].productname.value
          });
        }
      }      
    }
    if(sessionStorage.getItem('AddToCart')) {
      let sessionMessage = sessionStorage.getItem('AddToCart');
      let getSessionMessage = sessionMessage !== null ? sessionMessage : ""

      if(getSessionMessage !== "") {
        this.setState({
          cartmsgclass: getSessionMessage
        })
        sessionStorage.removeItem('AddToCart');
      }
    }
  }

  render() {
  	//const designVariant = typeof this.props.fields.designVariant === 'undefined' ? "" : this.props.fields.designVariant.value;

    let contentText = `“${this.state.productName}” has been added to your cart.`
    return(
      <div className={`woocommerce-message ${this.state.cartmsgclass}`} >
        <a href="/cart" className="button wc-forward">View Cart</a> {contentText}
      </div>
    );
  }
}

export default CartMessage;
