import React from "react";
import { NavLink } from "react-router-dom";
import {
  Text,
  Image,
  withSitecoreContext,
  RichText, Placeholder
} from "@sitecore-jss/sitecore-jss-react";
import ProductImagesGalleryV1 from "../ProductImagesGalleryV1";
import ProductImagesGalleryV2 from "../ProductImagesGalleryV2";
import './productSummary.css';
import Breadcrumbs from "../Breadcrumbs";
import ReactComponent from "../../ReactComponent"
import StarRatingV2 from "../StarRatingV2";
import i18next from 'i18next';
import Link from "react-router-dom/Link";
import { handleAddToCart } from "../Tagging";

class ProductSummary extends ReactComponent {

  state = {
    isMobile: false
  }

  componentDidMount() {
    this.updateIsMobile = this.updateIsMobile.bind(this)
    window.addEventListener('resize', this.updateIsMobile)

    this.updateIsMobile()
  }

  updateIsMobile() {
    if(window.innerWidth <= 768){
      this.setState({isMobile: true})
    }else{
      this.setState({isMobile: false})
    }
  }

  render() {
    const product = this.props.sitecoreContext.route.fields;
    let nbIng = product.productIngredients.length * 34;


    return (
      <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 main-wrap">
                <div className="main-content">
                  <div className="style-2 product"></div>
                    <React.Fragment>
                      <Breadcrumbs/>
                      <div className="row summary-container">
                        <div className="col-sm-6 entry-image">
                          {this.state.isMobile ? (
                          <ProductImagesGalleryV1 />
                          ):(
                          <ProductImagesGalleryV2 />
                          )}
                        </div>
                        <div className="col-sm-6 entry-summary">
                          <div className="summary">
                            <h1 className="product_title entry-title">
                              <Text field={product && product.title} />
                            </h1>
                            <h4 className="product_subtitle"><Text field={product && product.productShortDescription} /></h4> 
                            <div className="commerce-product-rating mb-0">
                              <StarRatingV2 value={parseInt(product.rating.value)} />{" "}
                              {/* {product.rating && (Math.round(product.rating.value*100)/100).toFixed(1)}{" "} */}
                              {/* <ProductReviews productId={this.props.sitecoreContext.itemId} /> */}
                            </div>
                            <br />
                            <div className="product-excerpt">
                                <RichText
                                  field={product && product.productLongDescription}
                                />
                            </div>
                            <div className="dropdownCustom mt-4 col-md-12">
                              <Placeholder name="dropdown-ecommerce" rendering={this.props.rendering} />
                              {this.props.sitecoreContext.route.fields.findSalon &&
                              (this.props.sitecoreContext.route.fields.findSalon.value.linktype !== undefined &&
                                  this.props.sitecoreContext.route.fields.findSalon.value.href.startsWith("http") === true ?
                                      <Link onClick={() => handleAddToCart(this.props.sitecoreContext.route.fields.findSalon.value.text)} field={this.props.sitecoreContext.route.fields.findSalon} className={"btn btn-secondary text-uppercase findSalon"} />
                                      :
                                      this.props.sitecoreContext.route.fields.findSalon.value.linktype !== undefined &&
                                      <NavLink onClick={() => handleAddToCart(this.props.sitecoreContext.route.fields.findSalon.value.text)} to={this.props.sitecoreContext.route.fields.findSalon} className={"btn btn-secondary text-uppercase findSalon"}>
                                        {this.props.sitecoreContext.route.fields.findSalon.value.text}
                                      </NavLink>
                              )
                              }
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-xs-12 prodAd prodSumRT">
                          <div className="col-xs-12 row-product-advant">
                            <RichText
                                field={product && product.howToUse}
                                className={"howToUse-entry"}
                            />
                            <div className="entry-relevantAdvantages">
                              <RichText
                                  field={product && product.relevantAdvantages}
                                  tag={"h2"}
                                  className={"w-100 mt-0 decorated-title"}
                              />
                              <ul>
                                {product &&
                                product.productAdvantages.map((item, revIndex) => (
                                  <React.Fragment key={revIndex}>
                                    <li>
                                      <img
                                          src={item.fields && item.fields.Image  && item.fields.Image.value.src}
                                      />
                                      <div className="product-results-list-desc">
                                        <Text
                                            field={item.fields && item.fields.Title}
                                            editable={true}
                                            tag={"p"}
                                        />
                                      </div>
                                    </li>
                                  </React.Fragment>
                                ))}
                              </ul>
                            </div>
                          </div>
                          <div className="col-xs-12 entry-ingredient">
                            <div>
                              <RichText className="decorated-title" field={product && product.ingredientTitle} tag={"h2"}/>
                              <ul className="product-ingredients-list">
                                {product.productIngredients &&
                                  product.productIngredients.map((ing, index) => {
                                    return (
                                        <li key={index} className="product-ingredients-list-item">
                                          <NavLink to={ing.fields.Link.value.href}>
                                            <img loading="lazy" src={ing.fields && ing.fields.Image  && ing.fields.Image.value.src} className="product-ingredients-list-img"/>
                                            <RichText field={ing.fields && ing.fields.Title} tag={"h3"} className="product-ingredients-list-title"/>
                                          </NavLink>
                                        </li>
                                    );
                                  })
                                }
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-xs-12 entry-composition">
                          <RichText
                              field={product && product.composition}
                              className={"text-composition w-100"}
                          />
                        </div>
                      </div>
                    </React.Fragment>
                    </div>          
                  </div>
                </div>
              </div>
       
    );
  }
}

export default withSitecoreContext()(ProductSummary);
