import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import InfiniteScroll from "react-infinite-scroll-component";
import ProductGridItem from '../ProductGridItem';
import { DotLoader } from 'react-spinners';
import ReactComponent from "../../ReactComponent";
import { css } from '@emotion/react';
import "./customProductsByCategoriesScrollable.css"

const override = css`
  margin: auto;
`;

class ProductsByCategoryScrollable extends ReactComponent {

	constructor(props) {
		super(props);
		this.state = {
			error: null,
			isLoaded: false,
      itemdata: [],
      items: [],
			after: 0,
			hasNextPage: true,
      initialize: false,
      selectedCategoryId: null,
      selectedCategoryName: null,
    };
    this.updateCategorySelection = this.updateCategorySelection.bind(this);
    this.loadItems = this.loadItems.bind(this);
	}

	componentDidMount() {

    if (this.props.fields.productCategories) {
      const url = window.location.href;
      let categoryName = "";
      let categoryId = "";
      if (url.includes('=')) {
        categoryId = url.substring(url.indexOf("=") + 1).toLowerCase();
        if (categoryId.includes('#')) categoryId = categoryId.replace('#','');
        if (categoryId.includes('-')) categoryId = categoryId.replace(/-/g,'').toLowerCase();
  
        for (let i=0; i<this.props.fields.productCategories.length; i++) {
          if (categoryId === this.props.fields.productCategories[i].id.replace(/-/g, '').toLowerCase()) categoryName = this.props.fields.productCategories[i].fields.title.value;
        }
        if (categoryName === "") categoryName = this.props.fields.productCategories[0].fields.title.value;
        if (!categoryId || categoryId === "") categoryId = this.props.fields.productCategories[0].id.replace(/-/g, '').toLowerCase();
      }
      else {
        categoryName = this.props.fields.productCategories[0].fields.title.value;
        categoryId = this.props.fields.productCategories[0].id.replace(/-/g, '').toLowerCase();
      }

      this.setState({
        initialize:true,
        selectedCategoryName: categoryName,
        selectedCategoryId: categoryId,
      });

    }
  }
  
  updateCategorySelection(categoryId, categoryName) {
    this.setState({
      selectedCategoryName: categoryName,
      selectedCategoryId: categoryId.replace(/-/g, '').toLowerCase(),
      items: [],
      after: 0,
      isLoaded: false,
    });
  }

	componentDidUpdate(prevProps, prevState) {

    if (this.state.itemdata !== prevState.itemdata) {
      if (this.state.itemdata.data) {
        if (this.state.itemdata.data.search.results.pageInfo.hasNextPage === false) this.setState({hasNextPage: false});
        else this.setState({hasNextPage: true});
        this.setState({
          items: [...this.state.items, ...this.state.itemdata.data.search.results.items],
        })
      }
    }
    
    if(this.state.initialize !== prevState.initialize || this.state.after !== prevState.after || this.state.selectedCategoryId !== prevState.selectedCategoryId) {
      //Query variables
      let catid = "";
      catid = this.state.selectedCategoryId;
			let itemsToLoad = this.props.fields.itemsToLoad !== null ? this.props.fields.itemsToLoad.value : 8;
			if (itemsToLoad === undefined || itemsToLoad === null || isNaN(itemsToLoad) || itemsToLoad === 0 ||  itemsToLoad < 0) itemsToLoad = 8;
			var after = "";
      after = this.state.after.toString();
      var INDEX = this.indexName;

	  //var API_ENDPOINT = configGraphQLSC.sitecoreMasterGraphQLEndpoint.replace("api/" + configGraphQLSC.jssAppName, "sitecore/api/graph/items/master");
      // var PRODUCTS_BY_CATEGORY = 'query={ search( first: '+itemsToLoad+', after: "'+after+'", fieldsEqual: [ {name: "_templatename", value: "ProductTemplate"}, {name: "categories_sm", value: "'+catid+'"}, ], rootItem: "/sitecore/content/'+ this.appName + '/") { results { totalCount pageInfo { startCursor endCursor hasNextPage hasPreviousPage } items { item { id url name ... on ProductTemplate { title { value } image1 { src value alt } image2 { src value alt } rating { numberValue } price { numberValue } categories { targetItems { id ...on ProductCategoryTemplate { title { value } } } } tag { targetItem { ...on ProductTagTemplate { title { value } backgroundColor { value } textColor { value } } } } salePrice { numberValue } } } } }  } }';
      var PRODUCTS_BY_CATEGORY = 'query={ search( index:"'+ INDEX+'" first: '+itemsToLoad+', after: "'+after+'", fieldsEqual: [ {name: "_templatename", value: "ProductRoute"}, {name: "productcategories", value: "'+catid+'"}, ], rootItem: "/sitecore/content/'+ this.appName +'") { results { totalCount pageInfo { startCursor endCursor hasNextPage hasPreviousPage } items { item { id url name ... on ProductDetails { title { value } longDescription:productLongDescription { value } image1 { src value alt } image2 { src value alt } image3 { src value alt } image4 { src value alt } rating { numberValue } price { numberValue } productCategories { targetItems { id ...on ProductCategoryTemplate { title { value } } } } tag { targetItem { name ...on ProductTagTemplate { title { value } backgroundColor { value } textColor { value } } } } salePrice { numberValue } productIcons{ value } } } } }  } }';
			fetch(this.getGraphqlEndpoint + PRODUCTS_BY_CATEGORY)
				.then(res => res.json())
				.then(
					(result) => {

						this.setState({
							isLoaded: true,
              itemdata: result,
						});
					},
			
					(error) => {
						this.setState({
							isLoaded: true,
							error
						});
					}
        )
    }
  }

  loadItems() {
    this.setState({
      after: this.state.after + 8,
    });
  }

render() {
    const loader = (
      <div className='sweet-loading' style={{marginTop:'50px', marginBottom: '50px'}}>
        <DotLoader
          css={override}
          sizeUnit={"px"}
          size={50}
          color={'#9fce4e'}
          loading={!this.state.isLoaded}
        />
      </div>
    );

    if (!this.state.isLoaded) return(
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12 main-wrap">
            <div className="main-content">
              <div data-layout="masonry" data-masonry-column="4" className="products-masonry masonry">
                <div className="masonry-filter scrollableProductsFilters">
                  <div className="filter-action filter-action-center">
                    <ul data-filter-key="filter">
                      {this.props.fields.productCategories && 
                        this.props.fields.productCategories.map((cat, index) => (
                          <li key={`filter-${index}`}>
                            <a 
                              data-masonry-toogle={this.state.selectedCategoryName === cat.fields.title.value ? "selected" : null}
                              data-filter-value={"." + cat.fields.title.value.toLowerCase()}
                              href="#" 
                              className={this.state.selectedCategoryName === cat.fields.title.value ? "selected" : null}
                              onClick={() => this.updateCategorySelection(cat.id, cat.fields.title.value)}
                              >
                              {cat.fields.title.value}
                            </a>
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                </div>
                <div className="products-masonry-wrap">
                  {loader}
                </div>
              </div>
            </div>
          </div>
				</div>
      </div>
    );
    if (!this.props.fields.productCategories) return("No categories contributed");
    if(!this.state.itemdata || !this.state.itemdata.data || !this.state.itemdata.data.search.results) return(`Empty results`);

    var items = [];
    var itemHasSelectedCategory = false;

    this.state.items.map((product, index) => {
      itemHasSelectedCategory = false;

      for(let category of product.item.productCategories.targetItems) {
        if (this.state.selectedCategoryName.toLowerCase() === category.title.value.toLowerCase()) {
          itemHasSelectedCategory = true;
        }
      }

      items.push(
        <li className={"product product-item masonry-item col-md-4 col-sm-6 " + this.state.selectedCategoryName.toLowerCase()} 
          key={"infiniteScrollItems-" + this.props.categoryId + "-" + index} 
          style={!itemHasSelectedCategory ? {display:'none'} : null}
          >
          <ProductGridItem product={product} />
        </li>
      )

      return true;
    });
		return(
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12 main-wrap">
            <div className="main-content">
              <div data-layout="masonry" data-masonry-column="4" className="products-masonry masonry">
                <div className="masonry-filter scrollableProductsFilters">
                  <div className="filter-action filter-action-center">
                    <ul data-filter-key="filter">
                      {this.props.fields.productCategories && 
                        this.props.fields.productCategories.map((cat, index) => (
                          <li key={`filter-${index}`}>
                            <a 
                              data-masonry-toogle={this.state.selectedCategoryName === cat.fields.title.value ? "selected" : null}
                              data-filter-value={"." + cat.fields.title.value.toLowerCase()}
                              href="#" 
                              className={this.state.selectedCategoryName === cat.fields.title.value ? "selected" : null}
                              onClick={() => this.updateCategorySelection(cat.id, cat.fields.title.value)}
                              >
                              {cat.fields.title.value}
                            </a>
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                </div>
                <div className="products-masonry-wrap">
                  {/* <InfiniteScroll
                    dataLength={this.state.items.length}
                    next={this.loadItems}
                    hasMore={this.state.hasNextPage}
                    loader={loader}
                  > */}
                    
                    <ul className="products masonry-products row masonry-wrap">
                      {items}
                    </ul>
                  {/* </InfiniteScroll> */}
                </div>
              </div>
            </div>
          </div>
				</div>
      </div>
		);
  }

}

export default withSitecoreContext()(ProductsByCategoryScrollable);
